<template>
  <b-button title="Edit" variant="warning" size="sm" @click="handleClick">
    <feather-icon icon="EditIcon"></feather-icon>
  </b-button>
</template>

<script>
export default {
  name: `EditButton`,
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>
