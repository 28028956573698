export default [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/dashboard/Main.vue"),
    meta: {
      action: `read`,
      resource: `dashboard`,
    },
  },
  {
    path: "/nawala-list",
    name: "nawala-list",
    component: () => import("@/views/nawala/List.vue"),
    meta: {
      action: `read`,
      resource: `nawala`,
    },
  },
  {
    path: "/client",
    name: "client-list",
    component: () => import("@/views/client/List.vue"),
    meta: {
      action: "read",
      resource: "client",
    },
  },
  {
    path: "/useraccount",
    name: "useraccount-list",
    component: () => import("@/views/useraccount/List.vue"),
    meta: {
      action: "read",
      resource: "useraccount",
    },
  },

  {
    path: "/config",
    name: "config",
    component: () => import("@/views/config/Configuration.vue"),
    meta: {
      action: "manage",
      resource: "config",
    },
  },
  {
    path: "/auth/login",
    name: "auth-login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      layout: "full",
      requiresAuth: false,
    },
  },
];
