<template>
  <div>
    Showing <span class="text-danger font-weight-bolder">{{ start }}</span> to
    <span class="text-danger font-weight-bolder">{{ end }}</span> of
    <span class="text-danger font-weight-bolder">{{ total }}</span>
    <span> Entries</span>
  </div>
</template>

<script>
export default {
  name: `PageInfo`,
  props: {
    pageNo: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  computed: {
    start() {
      return (this.pageNo - 1) * this.pageSize + 1;
    },
    end() {
      return this.pageNo * this.pageSize > this.total ? this.total : this.pageNo * this.pageSize;
    },
  },
};
</script>
