import api from "@api";
import { GET_REFRESH_TOKEN } from "@/services/service.localStorage";
import { updateAbility } from "@/libs/acl/ability";
import router from "@/router";

export default {
  async LOGIN({ commit, dispatch }, payload) {
    let resp = await api.post("auth/token", payload);
    commit("SET_TOKEN", resp.accessToken);
    commit("SET_REFRESH_TOKEN", resp.refreshToken);
    dispatch("GET_USER");
  },
  async REFRESH_TOKEN({ commit, dispatch }) {
    try {
      let resp = await api.post("auth/refreshtoken", { refreshToken: GET_REFRESH_TOKEN() });
      commit("SET_TOKEN", resp.accessToken);
      commit("SET_REFRESH_TOKEN", resp.refreshToken);
      dispatch("GET_USER");
    } catch (error) {
      dispatch("START_LOGIN");
    }
  },
  async GET_USER({ commit }) {
    let user = await api.get("me");
    commit("SET_USER", user);
    updateAbility(user);
  },
  async LOGOUT({ commit }) {
    api.post(`auth/revoke`, { token: GET_REFRESH_TOKEN() });
    commit("SET_TOKEN", null);
    commit("SET_USER", null);
    commit("SET_REFRESH_TOKEN", null);
  },
  async START_LOGIN({ commit }) {
    commit("SET_TOKEN", null);
    commit("SET_USER", null);
    commit("SET_REFRESH_TOKEN", null);
    router.push({ name: "auth-login" });
  },
};
