import Vue from "vue";

Vue.mixin({
  data() {
    return {
      vueNumericConfig: {
        minus: true,
        precision: 2,
      },
    };
  },
  computed: {},
  methods: {
    updateCollection(collection, keyCol, newRecord) {
      let idx = collection.findIndex(x => x[keyCol] == newRecord[keyCol]);
      collection[idx] = newRecord;
      return collection;
    },
    updateCollectionByIdx(collection, idx, newRecord) {
      collection[idx] = newRecord;
      return collection;
    },
    removeFromCollection(collection, keyCol, keyValue) {
      let idx = collection.findIndex(x => x[keyCol] == keyValue);
      collection.splice(idx, 1);
    },
    clearObject(obj) {
      for (var p in obj) {
        obj[p] = null;
      }
    },
    download(stream, filename) {
      var fileURL = window.URL.createObjectURL(new Blob([stream]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    successCopy(data) {
      this.showToast(`copy`, data);
    },
    pascalCase(str) {
      if (str)
        return str.toString().replace(/\w+/g, function (w) {
          return w[0].toUpperCase() + w.slice(1).toLowerCase();
        });
      else return "";
    },
  },
});
