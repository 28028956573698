import Vue from "vue";

//Format Date Time
Vue.filter("dateTime", function (value) {
  if (!value) return "";
  return Vue.moment(value).format("DD-MMM-YYYY HH:mm:ss");
});

//Format Date Only
Vue.filter("date", function (value) {
  if (!value) return "";
  return Vue.moment(value).format("DD-MMM-YYYY");
});

//Format Month Only
Vue.filter("month", function (value) {
  if (!value) return "";
  return Vue.moment(value).format("MMMM, YYYY");
});

//Format Time Only
Vue.filter("time", function (value) {
  if (!value) return "";
  return Vue.moment(value).format("HH:mm:ss");
});

//Convert LocalDate
Vue.filter("localDate", function (value) {
  if (!value) return "";
  let tzOffsetMin = new Date().getTimezoneOffset();
  return Vue.moment(value).add(-tzOffsetMin, "minutes").format("DD/MMM/YYYY HH:mm:ss");
});

Vue.filter(`customDate`, function (value) {
  if (!value) return "";
  const date = new Date(value);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return "today";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "yesterday";
  } else {
    return date.toLocaleDateString();
  }
});

Vue.filter(`timeAgo`, function (value) {
  const now = new Date();
  const past = new Date(value);
  const diffInSeconds = Math.floor((now - past) / 1000);

  const days = Math.floor(diffInSeconds / (3600 * 24));
  const hours = Math.floor((diffInSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
  const seconds = diffInSeconds % 60;

  if (days > 7) {
    const weeks = Math.floor(days / 7);
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ${hours} hour${hours > 1 ? "s" : ""} ${minutes} minute${
      minutes > 1 ? "s" : ""
    } ${seconds} second${seconds > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ${minutes} minute${minutes > 1 ? "s" : ""} ${seconds} second${
      seconds > 1 ? "s" : ""
    } ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ${seconds} second${seconds > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
});
