import Vue from "vue";
import numeral from "numeral";

//  CONVERT NUMBER
Vue.filter("number", function (value) {
  return numeral(value).format("0,0");
});

//  CONVERT DECIMAL
Vue.filter("decimal", function (value) {
  return numeral(value).format("0,0.00");
});
