<template>
  <b-form @submit.prevent="save">
    <b-form-group label="Old Password">
      <b-form-input v-model="form.oldPassword" type="password" placeholder="**************" class="mb-2"></b-form-input>
    </b-form-group>

    <b-form-group label="New Password">
      <b-form-input v-model="form.newPassword" type="password" placeholder="**************" class="mb-2"></b-form-input>
    </b-form-group>

    <b-form-group label="Confirm Password">
      <b-form-input
        v-model="form.confirmPassword"
        type="password"
        placeholder="**************"
        class="mb-2"
      ></b-form-input>
    </b-form-group>

    <hr />

    <div class="d-flex">
      <b-button class="ml-auto" variant="success" @click.stop="save">Change Password</b-button>
    </div>
  </b-form>
</template>

<script>
import api from "@api";
export default {
  name: `ChangePassword`,
  data() {
    return {
      form: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
    };
  },
  computed: {},
  methods: {
    async save() {
      // this.$store.dispatch("app/TOGGLE_PASSWORD");
      try {
        if (this.form.newPassword === this.form.confirmPassword) {
          await api.put("useraccount/change-password", this.form);
          this.$emit("onSaveSuccess");
          this.showToast(`success`, `Password has been changed`);
          this.clearObject(this.form);
        } else {
          this.showToast(`error`, `New Password and Confirm Password must be the same`);
        }
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
