<template>
  <b-button variant="danger" :size="size" @click="onClick">
    <feather-icon icon="XIcon"></feather-icon>
    {{ text }}
  </b-button>
</template>

<script>
export default {
  name: `CancelButton`,
  props: {
    text: {
      type: String,
      default: `Cancel`,
    },
    size: {
      type: String,
      default: `sm`,
    },
  },
  methods: {
    onClick(evt) {
      this.$emit(`click`, evt);
    },
  },
};
</script>
