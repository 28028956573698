export default [
  {
    action: `manage`,
    subject: `client`,
  },
  {
    action: `manage`,
    subject: `useraccount`,
  },
  {
    action: `manage`,
    subject: `config`,
  },
  {
    action: `super`,
    subject: `nawala`,
  },
];
