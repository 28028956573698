import { SET_TOKEN, SET_REFRESH_TOKEN } from "@/services/service.localStorage";

export default {
  SET_TOKEN(state, token) {
    SET_TOKEN(token);
    state.token = token;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_REFRESH_TOKEN(state, token) {
    SET_REFRESH_TOKEN(token);
    state.refreshToken = token;
  },
};
