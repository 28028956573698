import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import routeList from "./routes/routes";
import { GET_TOKEN } from "@/services/service.localStorage";

Vue.use(VueRouter);

const routes = [
  ...routeList,
  {
    path: "*",
    redirect: { name: `dashboard` },
  },
];

let router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: addRequiresAuthToRoutes(routes),
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  const authRequired = to.meta.requiresAuth;
  const tokenExists = GET_TOKEN() != null;
  if (authRequired && tokenExists) next();
  else if (authRequired && !tokenExists) next({ name: "auth-login" });
  else if (!authRequired && tokenExists) next({ name: "dashboard" });
  else next();
});

export default router;

function addRequiresAuthToRoutes(routes) {
  const newRoutes = routes.map(route => {
    if (route.meta && route.meta.requiresAuth == false) {
      return route;
    } else {
      let retVal = {
        ...route,
        meta: {
          ...route.meta,
          requiresAuth: true,
        },
      };
      if (retVal.children) {
        retVal.children = addRequiresAuthToRoutes(retVal.children);
      }

      return retVal;
    }
  });
  return newRoutes;
}
