<template>
  <b-button :disabled="disabled" type="submit" variant="success" :size="size" @click="handleClick">
    <div v-if="!disabled">
      <feather-icon v-if="!disabled" icon="SaveIcon" size="16"></feather-icon>
      {{ text }}
    </div>
    <b-spinner v-else variant="primary" small />
  </b-button>
</template>

<script>
export default {
  name: `SubmitButton`,

  props: {
    text: {
      type: String,
      default: `Save`,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: `sm`,
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>
