import Vue from "vue";
import { Ability } from "@casl/ability";
import DefaultAbility from "./default";
import AdminAbility from "./admin";
import ClientAbility from "./client";

Vue.prototype.$ability = new Ability([]);

function getAbility(roles) {
  let ability = [...DefaultAbility];
  if (!roles) return ability;
  if (roles.includes("ADM")) ability = [...ability, ...AdminAbility];
  if (roles.includes("CLNT")) ability = [...ability, ...ClientAbility];
  return ability;
}

function updateAbility(user) {
  let roles = null;
  if (user) roles = [user.roleCode];
  const abilities = getAbility(roles);
  Vue.prototype.$ability.update(abilities);
}

function removeAbility() {
  Vue.prototype.$ability.update([]);
}

export default Vue.prototype.$ability;

export { getAbility, updateAbility, removeAbility };
