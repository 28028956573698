<template>
  <b-button :title="content" :variant="variant" size="sm" @click="handleClick">
    <feather-icon :icon="icon"></feather-icon>
  </b-button>
</template>

<script>
export default {
  name: `DeleteButton`,
  props: {
    content: {
      type: String,
      default: `Remove`,
    },
    variant: {
      type: String,
      default: `danger`,
    },
    icon: {
      type: String,
      default: `Trash2Icon`,
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>
