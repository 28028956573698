export const SET_LOCALE = locale => {
  localStorage.setItem("userLocale", locale);
};

export const GET_LOCALE = () => {
  let locale = localStorage.getItem("userLocale");
  if (!locale) {
    locale = "en";
    SET_LOCALE("en");
  }
  return locale;
};

export const SET_TOKEN = token => {
  localStorage.setItem("accessToken", token);
  if (token == null) {
    localStorage.removeItem("accessToken");
  }
};

export const GET_TOKEN = () => {
  return localStorage.getItem("accessToken");
};

export const SET_REFRESH_TOKEN = token => {
  localStorage.setItem("refreshToken", token);
  if (token == null) {
    localStorage.removeItem("refreshToken");
  }
};

export const GET_REFRESH_TOKEN = () => {
  return localStorage.getItem("refreshToken");
};
