import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import CancelButton from "@/components/buttons/CancelButton.vue";
import CreateButton from "@/components/buttons/CreateButton.vue";
import EditButton from "@/components/buttons/EditButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import ToggleButton from "@/components/buttons/ToggleButton.vue";
import PageInfo from "@/components/PageInfo.vue";

Vue.component(SubmitButton.name, SubmitButton);
Vue.component(CancelButton.name, CancelButton);
Vue.component(CreateButton.name, CreateButton);
Vue.component(EditButton.name, EditButton);
Vue.component(DeleteButton.name, DeleteButton);
Vue.component(ToggleButton.name, ToggleButton);

Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component(PageInfo.name, PageInfo);
