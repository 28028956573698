<template>
  <b-button :size="size" variant="primary" @click="handleClick">
    <feather-icon icon="PlusCircleIcon" :size="iconSize"></feather-icon>
    {{ text }}
  </b-button>
</template>

<script>
export default {
  name: `CreateButton`,

  props: {
    text: {
      type: String,
      default: `Add New`,
    },
    size: {
      type: String,
      default: `sm`,
    },
    iconSize: {
      type: String,
      default: "16",
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>
