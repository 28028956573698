<template>
    <b-button :title="content" :variant="variant" size="sm" @click="handleClick">
      <feather-icon :icon="icon"></feather-icon>
    </b-button>
  </template>
  
  <script>
  export default {
    name: `ToggleButton`,
    props: {
      content: {
        type: String,
        default: `Deactivate`,
      },
      variant: {
        type: String,
        default: `secondary`,
      },
      icon: {
        type: String,
        default: `CircleIcon`,
      },
    },
    methods: {
      handleClick(evt) {
        this.$emit("click", evt);
      },
    },
  };
  </script>